const openedMixin = (theme, custom, drawerWidth) => ({
  width: drawerWidth,
  backgroundColor: custom.sidebar.background ?? "rgba(33, 0, 183, 0.5)",
  transition: theme.transitions.create(["all"], {
    easing: theme.transitions.easing.easeIn,
    duration: 1000,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme, custom) => ({
  backgroundColor: custom.sidebar.background,
  transition: theme.transitions.create(["all"], {
    easing: theme.transitions.easing.easeOut,
    duration: 1000,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const drawerStyle = (theme, display = true) => ({
  "& .MuiDrawer-paper": {
    marginTop: "5.8vh",
    padding: "0.5vw",
  },
  display: display ? "block" : "none",
});

const listItemBtnStyle = (open) => ({
  minHeight: 48,
  justifyContent: open ? "inital" : "center",
  px: 2.5,
  textAlign: "center",
});

const listItemIconStyle = (open) => ({
  minWidth: 0,
  mr: open ? 3 : "auto",
  justifyContent: "center",
  textAlign: "center",
});

const listItemTextStyle = (open, theme) => ({
  opacity: open ? 1 : 0,
  color: theme.sidebar.font,
});

const navStyle = (isActive, isPending) => ({
  color: isActive ? "red" : "white",
  fontWeight: isActive ? "bold" : "",
});

export {
  openedMixin,
  closedMixin,
  drawerStyle,
  listItemBtnStyle,
  listItemIconStyle,
  listItemTextStyle,
  navStyle,
};
